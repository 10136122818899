import { IDossierCostsViewModel, IResponse, IVoidResponse } from "viewModels";
import api from "services/api";
import { IUpdateFontValuesInputModel } from "inputModels";

const getFontValues = (userId: string) =>
  new Promise<IResponse<IDossierCostsViewModel>>((resolve, reject) => {
    api
      .get(`fontvalues/get-font-values/${userId}`)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

const updateFontValues = (input: IUpdateFontValuesInputModel) =>
  new Promise<IVoidResponse>((resolve, reject) => {
    api
      .put(`fontvalues/update-font-values`, input)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

export const FontValuesController = {
  getFontValues,
  updateFontValues,
};
