import styled from "styled-components";

export const Container = styled.div`
  .MuiOutlinedInput-input {
    padding: 0.5rem;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

export const ModalContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 20px;
  overflow: auto;

  .loading {
    padding: 10px;
    display: flex;
    gap: 10px;
  }
`;
