import {
  Backdrop,
  Box,
  Fade,
  Modal as MuiModal,
  ModalProps as MuiModalProps,
  SxProps,
  Theme,
} from "@mui/material";
import { Props } from "props";

export interface ModalProps extends Omit<MuiModalProps, "children"> {
  onClose: () => void;
  sx?: SxProps<Theme>;
}

const Modal: React.FC<ModalProps & Props> = ({
  sx,
  open,
  onClose,
  children,
  ...props
}: ModalProps & Props) => {
  return (
    <MuiModal
      disableScrollLock
      open={open}
      onClose={() => onClose?.()}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
      {...props}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "38vw",
            maxHeight: "90vh",
            overflowY: "auto",
            bgcolor: "background.paper",
            borderRadius: 2,
            p: 2,
            boxShadow: 24,
            outline: "none",
            ...sx,
          }}
        >
          {children}
        </Box>
      </Fade>
    </MuiModal>
  );
};

export default Modal;
