import {
  ILoginInputModel,
  IRecoverPasswordInputModel,
  IRegisterInputModel,
} from "inputModels";
import api from "services/api";
import { ILoginViewModel, IResponse, IVoidResponse } from "viewModels";

const login = (input: ILoginInputModel) =>
  new Promise<IResponse<ILoginViewModel>>((resolve, reject) => {
    api
      .post("auth/login", input)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

const register = (input: IRegisterInputModel) =>
  new Promise<IResponse<ILoginViewModel>>((resolve, reject) => {
    api
      .post("auth/signin", input)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

const recoverPassword = (input: IRecoverPasswordInputModel) =>
  new Promise<IVoidResponse>((resolve, reject) => {
    api
      .put("auth/recover-password", input)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

const refreshToken = (input: string) =>
  new Promise<IVoidResponse>((resolve, reject) => {
    api
      .post("auth/refresh-token", JSON.stringify(input))
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

const logout = () =>
  new Promise<IVoidResponse>((resolve, reject) => {
    api
      .post("auth/logout")
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

const updateConditionsAccepted = (id: string, status: boolean) =>
  new Promise<IVoidResponse>((resolve, reject) => {
    api
      .put(`auth/${id}`, status, {
        headers: { "Content-Type": "application/json" },
      })
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

const updateTrialAccount = (id: string, status: boolean) =>
  new Promise<IVoidResponse>((resolve, reject) => {
    api
      .put(`auth/trial/${id}`, status, {
        headers: { "Content-Type": "application/json" },
      })
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

export const AuthController = {
  login,
  recoverPassword,
  logout,
  refreshToken,
  register,
  updateConditionsAccepted,
  updateTrialAccount,
};
