import React from "react";
import { Container, Footer, Title } from "./styles";

import { Typography } from "@mui/material";
import CountUp from "react-countup";

interface KpiCardProps {
  title: string;
  value: number;
  icon: React.ReactElement;
  currency?: boolean;
  percent?: boolean;
  backgroundColor?: string;
}

const KpiCard: React.FC<KpiCardProps> = ({
  title,
  value,
  currency = false,
  percent = false,
  backgroundColor,
  icon,
}) => {
  return (
    <Container backgroundColor={backgroundColor}>
      {React.cloneElement(icon, { className: "icon" })}
      <Title>{title}</Title>
      <div className="balanceValue">
        {currency && (
          <Typography
            variant="h5"
            color="white"
            sx={{ fontWeight: 600, marginTop: "8px" }}
          >
            R$
          </Typography>
        )}
        <Typography
          variant="h2"
          color="white"
          sx={{ ml: 1, fontWeight: 600, opacity: 0.8 }}
        >
          {value % 1 !== 0 ? (
            <CountUp
              duration={2}
              end={value}
              decimals={2}
              decimal=","
              style={{ fontSize: 52 }}
            />
          ) : currency ? (
            <CountUp
              duration={2}
              decimals={2}
              end={value}
              decimal=","
              style={{ fontSize: 52 }}
            />
          ) : (
            <CountUp
              duration={2}
              end={value}
              decimal=","
              style={{ fontSize: 52 }}
            />
          )}
        </Typography>
        {percent && (
          <Typography
            variant="h5"
            color="white"
            sx={{ fontWeight: 600, marginTop: "8px" }}
          >
            %
          </Typography>
        )}
      </div>

      <Footer></Footer>
    </Container>
  );
};

export default KpiCard;
