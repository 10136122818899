import { LogUserContextProps } from "contexts-types";
import { Columns, FilterProps, Props } from "props";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useInfiniteQuery } from "react-query";
import { ILogUserViewModel } from "viewModels";
import { useAuth } from "./auth";
import { IOrderFieldInputModel } from "inputModels";
import { LogUserController } from "controllers";

const PAGE_SIZE = 15;
const LogUserContext = createContext<LogUserContextProps>(
  {} as LogUserContextProps
);
const LogUser: React.FC<Props> = ({ children }) => {
  const { user, logged } = useAuth();
  const [orderBy, setOrder] = useState<IOrderFieldInputModel<Columns>[]>([]);
  const [logsUser, setLogsUser] = useState<ILogUserViewModel[]>([]);
  const [filter, setFilter] = useState<FilterProps>();
  const [reportsFinancials, setReportsFinancials] = useState<
    ILogUserViewModel[]
  >([]);

  const {
    data: results,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetch,
  } = useInfiniteQuery(
    ["logsUser", orderBy, filter],
    ({ pageParam = 1 }) =>
      LogUserController.listAll({
        orderBy,
        page: pageParam,
        take: PAGE_SIZE,
        term: filter?.term,
        filter: filter?.term ? filter?.filter : undefined,
      }).then((res) => res.data),
    {
      enabled: !!user?.id && logged,
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = allPages.length + 1;
        return lastPage.length < PAGE_SIZE ? undefined : nextPage;
      },
    }
  );

  const {
    data: dataReportFinancials,
    isLoading: isLoadingReportFinancial,
    hasNextPage: hasNextPageReportFinancial,
    fetchNextPage: fetchNextPageReportFinancial,
    isFetchingNextPage: isFetchingNextPageReportFinancial,
    refetch: refetchReportFinancial,
  } = useInfiniteQuery(
    ["reportFinancial", orderBy, filter],
    ({ pageParam = 1 }) =>
      LogUserController.getReportFinancial({
        orderBy,
        page: pageParam,
        take: PAGE_SIZE,
        term: filter?.term,
        filter: filter?.term ? filter?.filter : undefined,
      }).then((res) => res.data),
    {
      enabled: !!user?.id && logged,
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = allPages.length + 1;
        return lastPage.length < PAGE_SIZE ? undefined : nextPage;
      },
    }
  );

  const handleFetchLogUser = () => {
    refetch();
  };

  const handleFetchNextPage = () => {
    fetchNextPage();
  };

  const handleRefetchReportFinancial = () => {
    refetchReportFinancial();
  };

  const handleFetchNextPageReportFinancial = () => {
    fetchNextPageReportFinancial();
  };

  useEffect(() => {
    setLogsUser(results?.pages?.flat() ?? []);
  }, [results, setLogsUser]);

  useEffect(() => {
    setReportsFinancials(dataReportFinancials?.pages?.flat() ?? []);
  }, [dataReportFinancials, setReportsFinancials]);

  return (
    <LogUserContext.Provider
      value={{
        logsUser,
        setLogsUser,
        isLoading,
        orderBy,
        setOrder,
        filter,
        setFilter,
        handleFetchLogUser,
        handleFetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        isLoadingReportFinancial,
        handleFetchNextPageReportFinancial,
        isFetchingNextPageReportFinancial,
        handleRefetchReportFinancial,
        hasNextPageReportFinancial,
        reportsFinancials,
        setReportsFinancials,
      }}
    >
      {children}
    </LogUserContext.Provider>
  );
};

export const useLogUser: () => LogUserContextProps = () =>
  useContext(LogUserContext);

export default LogUser;
