import { Modal } from "components";
import { LogDisplay, ModalContent, ModalHeader } from "./styles";
import { IconButton, TextField, Typography } from "@mui/material";
import { Cancel } from "@mui/icons-material";
import { IFontReportViewModel } from "viewModels";

interface FontTestLogModalProps {
  onClose: () => void;
  open: boolean;
  font: IFontReportViewModel;
}

function FontTestLogModal({ onClose, open, font }: FontTestLogModalProps) {
  const formatDate = (date: Date | string): string => {
    const parsedDate = new Date(date);
    if (isNaN(parsedDate.getTime())) {
      return "Data inválida";
    }

    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };
    return new Intl.DateTimeFormat("pt-BR", options).format(parsedDate);
  };
  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        width: "40vw",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <ModalHeader>
        <Typography
          variant="h6"
          component="h2"
          color="primary"
          fontWeight="bold"
        >
          Detalhes da Falha
        </Typography>
        <IconButton size="small" onClick={onClose} color="error">
          <Cancel />
        </IconButton>
      </ModalHeader>

      <Typography variant="body1" fontWeight="bold" gutterBottom>
        Fonte:
        <span style={{ fontWeight: "normal" }}> {font.font.name}</span>
      </Typography>
      <Typography variant="body1" fontWeight="bold" gutterBottom>
        Identificador:
        <span style={{ fontWeight: "normal" }}> {font.key}</span>
      </Typography>

      <Typography variant="body1" fontWeight="bold" gutterBottom>
        Verificado em
        <span style={{ fontWeight: "normal" }}>
          {" "}
          {formatDate(font.checkedAt)}
        </span>
      </Typography>
      <ModalContent>
        <LogDisplay>{font.log}</LogDisplay>
      </ModalContent>
    </Modal>
  );
}

export default FontTestLogModal;
