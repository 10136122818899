import styled from "styled-components";

export const Container = styled.div`
  padding: 20px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  .value {
    font-size: 18px;
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

export const ModalContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 20px;
  overflow: auto;

  span {
    font-size: 16px;
  }

  .value {
    font-size: 18px;
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  background-color: white;
  position: sticky;
  bottom: 0;
  z-index: 1;
  border-top: 1px solid ${({ theme }) => theme.colors.grey_200};
`;
