import { AuthContextProps } from "contexts-types";
import { usePersistedState } from "hooks";
import jwtDecode from "jwt-decode";
import { Props } from "props";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";

import { setters } from "services/api";
import { ILoginViewModel, IResponse, IUserViewModel } from "viewModels";

const AuthContext = createContext<AuthContextProps>({} as AuthContextProps);

const Auth: React.FC<Props> = ({ children }) => {
  const [token, setToken] = usePersistedState<string | undefined>(
    "@legal_audit_token",
    undefined
  );
  const [, setRefreshToken] = usePersistedState<string | undefined>(
    "@legal_audit_refresh_token",
    undefined
  );
  const [user, setUser] = usePersistedState<IUserViewModel>(
    "@legal_audit_user",
    {} as IUserViewModel,
    true
  );

  const logged = useMemo(
    () => (token ? (jwtDecode(token) as any).exp > Date.now() / 1000 : false),
    [token]
  );

  const signIn = useCallback(
    (response: IResponse<ILoginViewModel>) => {
      if (!response.success) return;
      const auth = response.data;
      setUser(auth.user || ({} as IUserViewModel));
      setToken(auth.token);
      setRefreshToken(auth.refreshToken);
    },
    [setRefreshToken, setToken, setUser]
  );

  const signOut = useCallback(() => {
    setUser({} as IUserViewModel);
    setToken(undefined);
    setRefreshToken(undefined);
  }, [setRefreshToken, setToken, setUser]);

  useEffect(() => {
    setters.setOnError(signOut);
    setters.setOnRefreshToken(signIn);
  }, [signIn, signOut]);

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        logged,
        signIn,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth: () => AuthContextProps = () => useContext(AuthContext);

export default Auth;
