import { PaymentContextProps } from "contexts-types";
import { Props } from "props";
import { createContext, useContext, useEffect, useState } from "react";
import {
  FinancialHistoryDataViewModel,
  IFinancialCardsDataViewModel,
  ITransactionViewModel,
} from "viewModels";
import { useAuth } from "./auth";
import { PaymentsController } from "controllers/PaymentsController";
import useWebSocket from "hooks/useWebSocket";
import { useInfiniteQuery } from "react-query";
import { IOrderFieldInputModel } from "inputModels";
import { Columns } from "props";
import { FilterProps } from "props";
import { PaymentStatus, TransactionType } from "types/enums";

const PaymentContext = createContext<PaymentContextProps>(
  {} as PaymentContextProps
);

const PAGE_SIZE = 10;
const PaymentProvider: React.FC<Props> = ({ children }: Props) => {
  const { user, logged } = useAuth();

  const [transactions, setTransactions] = useState<ITransactionViewModel[]>([]);
  const [cardsData, setCardsData] = useState<IFinancialCardsDataViewModel>();
  const [financialHistoryData, setFinancialHistoryData] = useState<
    FinancialHistoryDataViewModel[]
  >([]);

  const [loadingCards, setLoadingCards] = useState<boolean>(false);
  const [loadingHistory, setloadingHistory] = useState<boolean>(false);

  const [orderBy] = useState<IOrderFieldInputModel<Columns>[]>([]); // Futuramente pode adicionar ordenacao
  const [filter] = useState<FilterProps>(); // Futuramente pode adicionar filtro

  const {
    data: results,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetch,
    isFetching,
  } = useInfiniteQuery(
    ["logs", orderBy, filter],
    ({ pageParam = 1 }) =>
      PaymentsController.getAllTransactions({
        orderBy,
        page: pageParam,
        take: PAGE_SIZE,
        term: filter?.term,
        filter: filter?.term ? filter?.filter : undefined,
      }).then((res) => res.data),
    {
      enabled: !!user?.id && logged,
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = allPages.length + 1;
        return lastPage.length < PAGE_SIZE ? undefined : nextPage;
      },
    }
  );
  const handleFetch = () => {
    refetch();
  };

  const handleFetchNextPage = () => {
    fetchNextPage();
  };

  useEffect(() => {
    setTransactions(results?.pages?.flat() ?? []);
  }, [results, setTransactions]);

  useEffect(() => {
    if (!logged) {
      return;
    }
    setLoadingCards(true);
    setloadingHistory(true);

    PaymentsController.getDashboardCardsData()
      .then((response) => {
        setCardsData(response.data);
      })
      .finally(() => setLoadingCards(false));

    PaymentsController.getFinancialHistoryData()
      .then((response) => {
        setFinancialHistoryData(response.data);
      })
      .finally(() => setloadingHistory(false));
  }, [logged]);

  useWebSocket<ITransactionViewModel>({
    url: `transaction/${user?.id}`,
    onMessage: (transaction) => {
      setCardsData((prevState) => {
        if (prevState) {
          if (
            transaction.status === PaymentStatus.COMPLETED &&
            transaction.transactionType === TransactionType.CREDIT
          ) {
            return {
              ...prevState,
              currentMonthCredits:
                prevState.currentMonthCredits + transaction.amount / 100, // Adiciona o valor aos creditos mensais
            };
          }

          if (
            transaction.status === PaymentStatus.COMPLETED &&
            transaction.transactionType === TransactionType.DEBIT
          ) {
            return {
              ...prevState,
              currentMonthCredits:
                prevState.currentMonthSpent + transaction.amount / 100, // Adiciona o valor aos creditos mensais
            };
          }
        }
        return prevState;
      });

      setTransactions((prev) => {
        const existingTransactionIndex = prev.findIndex(
          (t) => t.id === transaction.id
        );
        if (existingTransactionIndex !== -1) {
          const updatedTransactions = [...prev];
          updatedTransactions[existingTransactionIndex] = transaction;
          return updatedTransactions;
        } else {
          setCardsData((prevState) => {
            if (prevState) {
              return {
                ...prevState,
                totalTransactions: prevState.totalTransactions + 1, //incrementa as transacoes se for uma nova
              };
            }
            return prevState;
          });
          return [transaction, ...prev];
        }
      });
    },
  });

  useWebSocket<number>({
    url: `balance/${user?.id}`,
    onMessage: (value) => {
      setCardsData((prevState) => {
        if (prevState) {
          return {
            ...prevState,
            balance: value, //incrementa as transacoes se for uma nova
          };
        }
        return prevState;
      });
    },
  });

  return (
    <PaymentContext.Provider
      value={{
        transactions,
        hasNextPage,
        handleFetch,
        handleFetchNextPage,
        isFetching,
        isFetchingNextPage,
        cardsData,
        financialHistoryData,
        loadingCards,
        loadingHistory,
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
};

export const usePayments: () => PaymentContextProps = () =>
  useContext(PaymentContext);

export default PaymentProvider;
