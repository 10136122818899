import { Modal } from "components";
import { LogDisplay, ModalContent, ModalHeader } from "./styles";
import {
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { Cancel } from "@mui/icons-material";
import { IFontStatusHistoryViewModel, IFontStatusRecord } from "viewModels";
import { FontStatus } from "types/enums";

interface FontTestLogModalProps {
  onClose: () => void;
  open: boolean;
  history: IFontStatusHistoryViewModel;
}

function FontStatusDetailsModal({
  onClose,
  open,
  history,
}: FontTestLogModalProps) {
  // Função para formatar a data
  const formatDate = (date: Date | string): string => {
    const parsedDate = new Date(date);
    if (isNaN(parsedDate.getTime())) {
      return "Data inválida";
    }

    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };
    return new Intl.DateTimeFormat("pt-BR", options).format(parsedDate);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        width: "40vw",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <ModalHeader>
        <Typography
          variant="h6"
          component="h2"
          color="primary"
          fontWeight="bold"
        >
          Detalhes da Fonte
        </Typography>
        <IconButton size="small" onClick={onClose} color="error">
          <Cancel />
        </IconButton>
      </ModalHeader>

      <Typography variant="body1" fontWeight="bold" gutterBottom>
        Fonte:
        <span style={{ fontWeight: "normal" }}> {history.fontName}</span>
      </Typography>
      <Typography variant="body1" fontWeight="bold" gutterBottom>
        Identificador:
        <span style={{ fontWeight: "normal" }}> {history.fontKey}</span>
      </Typography>

      {/* Exibição dos status e datas */}
      <ModalContent>
        <Typography variant="body1" fontWeight="bold" gutterBottom>
          Histórico de Status:
        </Typography>

        <LogDisplay>
          <List>
            {history.statusHistory.map(
              (statusRecord: IFontStatusRecord, index: number) => (
                <ListItem key={index}>
                  <ListItemText
                    primary={`${
                      statusRecord.status === FontStatus.AVAILABLE
                        ? "Disponível"
                        : "Indisponível"
                    }`}
                    secondary={`Em ${formatDate(statusRecord.date)}`}
                  />
                </ListItem>
              )
            )}
          </List>
        </LogDisplay>
      </ModalContent>
    </Modal>
  );
}

export default FontStatusDetailsModal;
