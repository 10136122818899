import {
  LabelImportant,
  Edit,
  DoDisturb,
  Check,
  Search,
  KeyboardArrowDown,
  Sort,
  KeyboardDoubleArrowDown,
} from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Container,
  FormControlLabel,
  Grid,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { AppBar, Modals } from "components";
import { Controller, useForm } from "react-hook-form";
import { ContentTable } from "./styles";
import { useEffect, useMemo, useState } from "react";
import {
  IAvailableExternalFontViewModel,
  IModelDossierViewModel,
} from "viewModels";
import { useQuery } from "react-query";
import { DossierController } from "controllers/DossierController";
import { useAlert, useAuth, useModel } from "contexts";
import { IModelDossierInputModel } from "inputModels";
import { ModelDossierController } from "controllers/ModelDossierController";
import { Columns } from "props";
import { v4 as uuid } from "uuid";
import { SearchType } from "types/enums";

const DossierModel = () => {
  const [parent] = useAutoAnimate({
    duration: 200,
    easing: "linear",
  });
  const alert = useAlert();
  const {
    orderBy,
    setOrder,
    hasNextPage,
    models,
    setModels,
    handleFetchNextPage,
    isFetchingNextPage,
    handleFetch,
  } = useModel();
  const { user } = useAuth();
  const [isLoading] = useState(false);
  const [warningDisplayed, setWarningDisplayed] = useState<boolean>(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [isOpenFontsModal, setIsOpenFontsModal] = useState(false);
  const theme = useTheme();
  const [requesting] = useState(false);
  const [selectedFonts, setSelectedFonts] = useState<
    IAvailableExternalFontViewModel[]
  >([]);
  const [selectedModel, setSelectedModel] = useState<IModelDossierViewModel>();
  const [externalFonts, setExternalFonts] = useState<
    IAvailableExternalFontViewModel[]
  >([]);
  const [externalFontsFiltered, setExternalFontsFiltered] = useState<
    IAvailableExternalFontViewModel[]
  >([]);
  const [defaultValues] = useState({
    name: "",
    filterModels: "",
    filterFonts: "",
    selectedFonts: [""],
  });
  const {
    control,
    watch,
    clearErrors,
    reset,
    setError,
    formState: { errors },
  } = useForm({
    shouldFocusError: false,
    reValidateMode: "onChange",
    defaultValues: useMemo(() => defaultValues, [defaultValues]),
  });
  const data = watch();

  const handleDisableDossierModel = (id: string, status: boolean) => {
    ModelDossierController.updateDisableModel(id, status).then(() => {
      setModels((prev) => {
        const newModels = [...prev];
        const idx = newModels.findIndex((item) => item.id === id);
        if (idx !== -1) {
          newModels[idx] = {
            ...newModels[idx],
            disabled: !newModels[idx].disabled,
          };
        }
        return newModels;
      });
    });
  };

  const verifySelected = (
    newSelecteds: IAvailableExternalFontViewModel[],
    item: IAvailableExternalFontViewModel
  ) => {
    if (
      !newSelecteds.some((s) => s.value.type === SearchType.CPF) &&
      item.value.type === SearchType.CPF
    ) {
      setExternalFontsFiltered(
        externalFonts.filter((f) => f.value.type !== SearchType.CNPJ)
      );
      if (
        newSelecteds.filter((sf) => sf.value.type === SearchType.CPF).length ===
        0
      ) {
        alert.show({
          type: "alert",
          title: `Fonte de CPF selecionada:`,
          description: `${item.value.name}`,
          subtitle:
            "As fontes disponíveis foram atualizadas para não conter fontes de CNPJ.",
          timeout: 10000,
        });
      }
    } else if (
      !newSelecteds.some((sf) => sf.value.type === SearchType.CNPJ) &&
      item.value.type === SearchType.CNPJ
    ) {
      setExternalFontsFiltered(
        externalFonts.filter((f) => f.value.type !== SearchType.CPF)
      );
      if (
        newSelecteds.filter((sf) => sf.value.type === SearchType.CNPJ)
          .length === 0
      ) {
        alert.show({
          type: "alert",
          title: `Fonte de CNPJ selecionada:`,
          subtitle:
            "As fontes disponíveis foram atualizadas para não conter fontes de CPF.",
          description: `${item.value.name}`,
          timeout: 10000,
        });
      }
    }
  };
  const verifyDesselected = (
    newSelecteds: IAvailableExternalFontViewModel[],
    item: IAvailableExternalFontViewModel
  ) => {
    if (
      !newSelecteds.some((s) => s.value.type === SearchType.CPF) &&
      !newSelecteds.some((s) => s.value.type === SearchType.CNPJ) &&
      (item.value.type === SearchType.CPF ||
        item.value.type === SearchType.CNPJ)
    ) {
      setExternalFontsFiltered(externalFonts);
      alert.show({
        type: "alert",
        title: "Atenção",
        description:
          "As fontes foram atualizadas para conter todas as fontes disponíveis",
        timeout: 10000,
      });
    }
  };
  const handleSelect = (item: IAvailableExternalFontViewModel) => () => {
    clearErrors("selectedFonts");
    const newSelecteds = [...selectedFonts];
    const idx = newSelecteds.indexOf(item);
    if (idx !== -1) {
      newSelecteds.splice(idx, 1);
      verifyDesselected(newSelecteds, item);
    } else {
      verifySelected(newSelecteds, item);
      newSelecteds.push(item);
    }

    setSelectedFonts(newSelecteds);

    if (selectedFonts.length >= 20 && !warningDisplayed) {
      alert.show({
        type: "alert",
        title: "Atenção:",
        description:
          "Selecionar mais que 20 fontes gera custo adicional na geração de dossiês.",
        timeout: 10000,
      });
      setWarningDisplayed(true);
    }

    if (warningDisplayed && selectedFonts.length <= 20) {
      setWarningDisplayed(false);
    }
  };

  const handleSelectModel = (model: IModelDossierViewModel) => {
    setSelectedModel(model);
  };

  const handleSelectFontType = (type: number) => {
    if (type === 1) {
      setExternalFontsFiltered(
        externalFonts.filter(
          (f) =>
            f.value.type === SearchType.CPF || f.value.type === SearchType.BOTH
        )
      );
    } else if (type === 2) {
      setExternalFontsFiltered(
        externalFonts.filter(
          (f) =>
            f.value.type === SearchType.CNPJ || f.value.type === SearchType.BOTH
        )
      );
    } else {
      setExternalFontsFiltered(externalFonts);
    }
  };

  const handleCreateModel = () => {
    let hasError = false;
    if (!user?.id) return;

    if (!selectedFonts.length) {
      setError("selectedFonts", { message: "Selecione ao menos uma fonte" });
      hasError = true;
    }

    if (models?.some((item) => item.name === data.name)) {
      setError("name", { message: "Nome do modelo já existe" });
      hasError = true;
    }

    if (!data.name) {
      setError("name", { message: "Informe o nome" });
      hasError = true;
    }
    if (hasError) return;
    const input: IModelDossierInputModel = {
      name: data.name,
      fonts: selectedFonts.map((item) => item.key),
      createdById: user.id,
      type: selectedFonts.some((s) => s.value.type === SearchType.CPF)
        ? SearchType.CPF
        : selectedFonts.some((s) => s.value.type === SearchType.CNPJ)
        ? SearchType.CNPJ
        : SearchType.BOTH,
    };
    ModelDossierController.insert(input).then(() => {
      alert.show({
        type: "alert",
        title: "Sucesso:",
        description: "Modelo criado com sucesso!",
        timeout: 3000,
      });
      handleFetch();
      setSelectedFonts([]);
      setExternalFontsFiltered(externalFonts);
    });
    reset();
  };
  const handleSort = (key: Columns) => () => {
    setOrder((prev) => {
      const newOrder = [...prev];
      const oldOrderIdx = prev.findIndex((o) => o.key === key);
      if (oldOrderIdx === -1) {
        newOrder.push({
          key,
          direction: "asc",
        });
      } else if (newOrder[oldOrderIdx].direction === "asc") {
        newOrder[oldOrderIdx].direction = "desc";
      } else {
        newOrder.splice(oldOrderIdx, 1);
      }
      return newOrder;
    });
  };

  const getSortIcon = (key: Columns) => (
    <IconButton size="small" onClick={handleSort(key)}>
      {orderBy.find((o) => o.key === key) ? (
        <KeyboardArrowDown
          sx={{
            transition: "all 0.5s ease-in",
            transform:
              orderBy.find((o) => o.key === key)?.direction === "asc"
                ? "rotate(-180deg)"
                : "",
          }}
        />
      ) : (
        <Sort />
      )}
    </IconButton>
  );

  useQuery(
    "external-fonts",
    () =>
      DossierController.getExternalFonts().then((res) => {
        setExternalFonts(
          res.data.filter((f) => f.value.type !== SearchType.DISABLED)
        );
      }),
    {
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    setExternalFontsFiltered(externalFonts);
  }, [externalFonts]);
  return (
    <>
      <AppBar />
      <Container
        sx={{
          flexGrow: 1,
          minWidth: "90vw",
          minHeight: "100vh",
          overflowY: "auto",
          pt: 10,
          pb: 4,
        }}
      >
        <Paper data-testid="divNewModel" sx={{ p: 5, mb: 4 }}>
          <Grid padding="0 1rem">
            <Typography variant="h4" color="primary" marginBottom={0.5}>
              Novo Modelo
            </Typography>
            <Typography paddingBottom="1rem" fontSize={16} color="primary">
              Selecione as fontes para serem geradas:
            </Typography>
            <Grid
              style={{
                marginBottom: "01rem",
                display: "flex",
              }}
            >
              <Controller
                control={control}
                name="filterFonts"
                render={({ field: { onChange, value } }) => (
                  <TextField
                    size="small"
                    label="Pesquisar Fontes"
                    variant="standard"
                    type="search"
                    autoComplete="off"
                    placeholder="O que deseja buscar?"
                    sx={{ width: 200 }}
                    onChange={(e) => onChange(e)}
                    InputProps={{
                      sx: {
                        pl: 1,
                      },
                      value: value,
                      endAdornment: <Search />,
                    }}
                  />
                )}
              />
              <RadioGroup
                style={{ marginLeft: "10rem" }}
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue={0}
                onChange={({ target }) => {
                  handleSelectFontType(parseInt(target.value));
                }}
              >
                <div>
                  <FormControlLabel value={1} control={<Radio />} label="CPF" />
                  <FormControlLabel
                    value={2}
                    control={<Radio />}
                    label="CNPJ"
                  />
                  <FormControlLabel
                    value={0}
                    control={<Radio />}
                    label="Ambos"
                  />
                </div>
              </RadioGroup>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={9}>
                <Grid
                  container
                  columns={{ xs: 4, sm: 8, md: 12 }}
                  maxHeight="11rem"
                  overflow="auto"
                  ref={parent}
                  border={`1px solid ${
                    errors.selectedFonts?.message
                      ? theme.palette.error.light
                      : theme.palette.primary.light
                  }`}
                  borderRadius="6px"
                  borderColor="primary"
                >
                  {externalFontsFiltered
                    ?.filter((f) =>
                      data.filterFonts
                        ? f.value.name
                            .toLocaleLowerCase()
                            .includes(data.filterFonts.toLocaleLowerCase())
                        : externalFontsFiltered
                    )
                    .map((item) => (
                      <Grid item xs={4} sm={8} md={6} key={item.key + uuid()}>
                        <ListItem
                          data-testid="listExternalFonts"
                          key={item.key}
                          onClick={handleSelect(item)}
                          sx={{
                            transition: "all 0.5s ease-in-out",
                            cursor: "pointer",
                            borderRadius: 1,
                            userSelect: "none",
                            ":hover": {
                              backgroundColor: "rgba(0, 0, 0, 0.05)",
                            },
                          }}
                        >
                          <ListItemIcon sx={{ minWidth: 30 }}>
                            <LabelImportant
                              color={
                                selectedFonts?.some((s) => s.key === item.key)
                                  ? "warning"
                                  : "inherit"
                              }
                            />
                          </ListItemIcon>
                          <ListItemText primary={item.value.name} />
                        </ListItem>
                      </Grid>
                    ))}
                </Grid>
                {errors.selectedFonts?.message && (
                  <Typography
                    padding="0.5rem 0"
                    fontSize={"0.75rem"}
                    color="error"
                    align="center"
                  >
                    {errors.selectedFonts?.message}
                  </Typography>
                )}
              </Grid>

              <Grid
                item
                xs={12}
                md={3}
                display="flex"
                flexDirection="column"
                gap="1.5rem"
                alignItems="center"
                justifyContent="center"
              >
                <Controller
                  name="name"
                  control={control}
                  render={({ field: { onChange, value, ...rest } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Nome"
                      error={!!errors.name?.message}
                      helperText={errors.name?.message}
                      placeholder="Informe o nome"
                      onChange={onChange}
                      {...rest}
                    />
                  )}
                />
                <Button
                  style={{ width: "12rem" }}
                  type="submit"
                  variant="contained"
                  sx={{ cursor: requesting ? "wait" : "pointer" }}
                  onClick={handleCreateModel}
                >
                  <Typography variant="button">Salvar</Typography>
                  {requesting && (
                    <CircularProgress
                      size={15}
                      sx={{ ml: 1, color: "white" }}
                    />
                  )}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <ContentTable data-testid="contentTable">
          <Grid display="flex" justifyContent="end" margin="0.5rem 0">
            <Controller
              control={control}
              name="filterModels"
              render={({ field: { onChange, value } }) => (
                <TextField
                  size="small"
                  label="Pesquisar"
                  variant="standard"
                  type="search"
                  placeholder="O que deseja buscar?"
                  sx={{ width: 250 }}
                  onChange={(e) => onChange(e.target.value)}
                  InputProps={{
                    sx: {
                      pl: 1,
                    },
                    endAdornment: <Search />,
                  }}
                />
              )}
            />
          </Grid>
          <Table stickyHeader aria-label="listModels">
            <TableHead>
              <TableRow
                sx={{
                  th: {
                    backgroundColor: "white",
                    "& p": {
                      fontWeight: "bold",
                      color: theme.palette.primary.main,
                    },
                    "& div": {
                      display: "flex",
                      alignItems: "center",
                      "& button": {
                        ml: 1,
                      },
                    },
                  },
                }}
              >
                <TableCell>
                  <Grid>
                    <Typography>Nome</Typography>
                    {getSortIcon("name")}
                  </Grid>
                </TableCell>
                <TableCell width={200}>
                  <Grid>
                    <Typography>Data de criação</Typography>
                    {getSortIcon("date")}
                  </Grid>
                </TableCell>
                <TableCell>
                  <Typography>Compatibilidade</Typography>
                </TableCell>
                <TableCell>
                  <Typography>Criado por</Typography>
                </TableCell>
                <TableCell>
                  <Grid>
                    <Typography>Fontes</Typography>
                  </Grid>
                </TableCell>
                <TableCell>
                  <Grid>
                    <Typography>Editar</Typography>
                  </Grid>
                </TableCell>
                <TableCell>
                  <Typography>Habilitado</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    <Grid
                      item
                      alignItems="center"
                      sx={{ minHeight: "65px !important" }}
                    >
                      <CircularProgress size={15} />
                      <Typography
                        paddingLeft={1}
                        variant="caption"
                        color="text"
                        sx={{ fontStyle: "italic" }}
                      >
                        Carregando lista de modelos...
                      </Typography>
                    </Grid>
                  </TableCell>
                </TableRow>
              ) : !models?.length ? (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    <Typography
                      paddingLeft={1}
                      variant="caption"
                      color="text"
                      sx={{ fontStyle: "italic" }}
                    >
                      Nenhum modelo encontrado...
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                models
                  .filter((m) =>
                    data.filterModels
                      ? m.name
                          .toLowerCase()
                          .includes(data.filterModels.toLowerCase()) ||
                        new Date(m.createdAt)
                          .toLocaleDateString("pt-BR", {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                          })
                          .includes(data.filterModels) ||
                        m.createdByName
                          .toLowerCase()
                          .includes(data.filterModels.toLowerCase())
                      : models
                  )
                  .map((item) => (
                    <TableRow key={item.id}>
                      <TableCell>
                        <Typography>{item.name}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {new Date(item.createdAt).toLocaleDateString(
                            "pt-BR",
                            {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            }
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {item.type === 0
                            ? "CPF"
                            : item.type === 1
                            ? "CNPJ"
                            : "CPF/CNPJ"}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{item.createdByName}</Typography>
                      </TableCell>
                      <TableCell>
                        <Button
                          onClick={() => {
                            setIsOpenFontsModal(true);
                            handleSelectModel(item);
                          }}
                        >
                          <Typography>Visualizar Fontes</Typography>
                        </Button>
                      </TableCell>
                      <TableCell>
                        <IconButton
                          data-testid="ButtonEditModel"
                          onClick={() => {
                            handleSelectModel(item);
                            setIsOpenEditModal(true);
                          }}
                        >
                          <Edit color="primary" />
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        <IconButton
                          data-testid="ButtonDisableModel"
                          onClick={() => {
                            handleDisableDossierModel(item.id, !item.disabled);
                          }}
                        >
                          {item.disabled ? (
                            <DoDisturb color="error" />
                          ) : (
                            <Check color="success" />
                          )}
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
              )}
              {hasNextPage && (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    {isFetchingNextPage ? (
                      <Grid
                        item
                        alignItems="center"
                        sx={{ minHeight: "65px !important" }}
                      >
                        <CircularProgress size={15} />
                        <Typography
                          paddingLeft={1}
                          variant="caption"
                          color="text"
                          sx={{ fontStyle: "italic" }}
                        >
                          Carregando itens...
                        </Typography>
                      </Grid>
                    ) : (
                      <Button
                        onClick={() => handleFetchNextPage()}
                        sx={{ px: 2 }}
                      >
                        <KeyboardDoubleArrowDown />
                        <Typography paddingLeft={2} variant="button">
                          Carregar mais...
                        </Typography>
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </ContentTable>
        {selectedModel && (
          <Modals.EditDossierModelModal
            open={isOpenEditModal}
            model={selectedModel}
            type={selectedModel?.type}
            baseExternalFonts={externalFonts}
            onClose={() => setIsOpenEditModal(false)}
          />
        )}
        <Modals.FontsDossierModelModal
          externalFonts={selectedModel?.fonts || []}
          open={isOpenFontsModal}
          onClose={() => setIsOpenFontsModal(false)}
        />
      </Container>
    </>
  );
};

export default DossierModel;
