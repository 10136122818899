import { DualAxes } from "@ant-design/plots";

export interface IHistoryData {
  data: string;
  debitos?: number;
  creditos?: number;
}

type Props = {
  data: IHistoryData[];
};

const LineChart: React.FC<Props> = ({ data }: Props) => {
  const config = {
    data,
    xField: "data",
    legend: true,
    children: [
      {
        type: "area",
        yField: "debitos",
        shapeField: "smooth",
        style: {
          fill: "rgba(91, 143, 249, 0.4)",
          stroke: "#5B8FF9",
          lineWidth: 2,
        },
        label: {
          style: {
            dy: -10,
            textAlign: "middle",
          },
          text: (datum: any) => `R$${datum.debitos}`,
        },
        axis: {
          y: {
            title: "Débitos",
            style: { titleFill: "#5B8FF9" },
          },
        },
      },
      {
        type: "area",
        yField: "creditos",
        shapeField: "smooth",
        style: {
          fill: "rgba(90, 216, 166, 0.4)",
          stroke: "#5AD8A6",
          lineWidth: 2,
        },
        label: {
          style: {
            dy: -10,
            textAlign: "middle",
          },
          text: (datum: any) => `R$${datum.creditos}`,
        },

        axis: {
          y: {
            position: "right",
            title: "Créditos",
            style: { titleFill: "#5AD8A6" },
          },
        },
      },
    ],
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        minWidth: "350px",
        minHeight: "350px",
      }}
    >
      <DualAxes {...config} />
    </div>
  );
};

export default LineChart;
