import {
  IFontStatusHistoryViewModel,
  IFontTestViewModel,
  IHistoryTestDataViewModel,
  IResponse,
  IVoidResponse,
} from "viewModels";
import api from "services/api";
import { IOrderedWithPaginationInputModel } from "inputModels";

const download = (data: any, name: string) => {
  const blob = new Blob([data], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  const uri = window.URL.createObjectURL(blob);

  if (!uri) return;
  const link = document.createElement("a");

  link.href = uri;
  link.setAttribute("download", name);
  link.rel = "noopener noreferrer";
  link.referrerPolicy = "no-referrer";
  link.click();

  link.parentElement?.removeChild(link);
  window.URL.revokeObjectURL(uri);
};

export const exportReports = (reportIds?: string[]): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    let url = "fontmonitoring/export-reports";

    if (reportIds && reportIds.length > 0) {
      const encodedIds = reportIds
        .map((id) => encodeURIComponent(id))
        .join(",");
      url += `?reportIds=${encodedIds}`;
    }

    api
      .get(url, {
        responseType: "blob",
      })
      .then(({ data }) => {
        let reportName = "Relátorio de Monitoramento de Fontes";

        if (reportIds && reportIds.length > 0) {
          reportName += ` - ${reportIds.join(",")}`;
        } else {
          reportName += ` - ${Date.now()}`;
        }
        download(data, `${reportName}.xlsx`);
        resolve();
      })
      .catch(reject);
  });
};

const getReport = <T>(input?: IOrderedWithPaginationInputModel<T>) =>
  new Promise<IResponse<IFontTestViewModel[]>>((resolve, reject) => {
    api
      .get(`fontmonitoring/get-report?page=${input?.page}&take=${input?.take}`)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

const getReportHistory = (startDate?: Date, endDate?: Date) =>
  new Promise<IResponse<IHistoryTestDataViewModel[]>>((resolve, reject) => {
    let url = "fontmonitoring/get-history";
    const queryParams: string[] = [];
    if (startDate)
      queryParams.push(`startDate=${startDate.toISOString().split("T")[0]}`);

    if (endDate)
      queryParams.push(`endDate=${endDate.toISOString().split("T")[0]}`);

    if (queryParams.length > 0) url += `?${queryParams.join("&")}`;

    api
      .get(url)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

const getFontsHistory = (startDate?: Date, endDate?: Date) =>
  new Promise<IResponse<IFontStatusHistoryViewModel[]>>((resolve, reject) => {
    let url = "fontmonitoring/get-fonts-history";
    const queryParams: string[] = [];
    if (startDate)
      queryParams.push(`startDate=${startDate.toISOString().split("T")[0]}`);

    if (endDate)
      queryParams.push(`endDate=${endDate.toISOString().split("T")[0]}`);

    if (queryParams.length > 0) url += `?${queryParams.join("&")}`;

    api
      .get(url)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

const triggerReport = () =>
  new Promise<IVoidResponse>((resolve, reject) => {
    api
      .post(`fontmonitoring/trigger-report`)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

export const FontMonitoringController = {
  getReport,
  triggerReport,
  getReportHistory,
  getFontsHistory,
  exportReports,
};
