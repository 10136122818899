import { Loading, Modal } from "components";
import {
  Button,
  IconButton,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress,
} from "@mui/material";
import { Cancel } from "@mui/icons-material";
import { useEffect, useRef, useState } from "react";
import { Container, ModalContent, ModalFooter, ModalHeader } from "./styles";
import { FontValuesController } from "controllers/FontValuesController";
import { IFontValuesViewModel, IUserViewModel } from "viewModels";
import { ConfigProvider, Input, Switch } from "antd";
import { AuthController } from "controllers";
import { useAuth, useCompanyUser } from "contexts";

interface FontValuesModalProps {
  onClose: () => void;
  user: IUserViewModel;
  viewer?: boolean;
  open: boolean;
}

function FontValuesModal({
  onClose,
  open,
  user,
  viewer = false,
}: FontValuesModalProps) {
  const { companyUsers, setCompanyUsers } = useCompanyUser();
  const { user: authUser, setUser } = useAuth();

  const modalRef = useRef<HTMLDivElement>(null);
  const [fontValues, setFontValues] = useState<IFontValuesViewModel[]>([]);
  const [originalFontValues, setOriginalFontValues] = useState<
    IFontValuesViewModel[]
  >([]);
  const [loadingFontValues, setLoadingFontValues] = useState<boolean>(false);
  const [baseValue, setBaseValue] = useState<number | string>(0);
  const [originalBaseValue, setOriginalBaseValue] = useState<number>(0);
  const [loadingSwitch, setLoadingSwitch] = useState<boolean>(false);
  const [trialStatus, setTrialStatus] = useState<boolean>(user.isTrialAccount);
  const [isSaveEnabled, setIsSaveEnabled] = useState<boolean>(false);
  const [savingChanges, setSavingChanges] = useState<boolean>(false);
  //
  const getFontValues = async () => {
    setLoadingFontValues(true);
    FontValuesController.getFontValues(user.id)
      .then((response) => {
        setBaseValue(response.data.minimumCost);
        setOriginalBaseValue(response.data.minimumCost);

        setOriginalFontValues(response.data.fontPrices);
        setFontValues(response.data.fontPrices);
      })
      .finally(() => {
        setLoadingFontValues(false);
      });
  };

  const handleEditBaseValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    // Permite o campo vazio ou converte para float caso haja um número
    setBaseValue(inputValue === "" ? "" : parseFloat(inputValue));
  };

  const handleEditPrice = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const inputValue = event.target.value;
    const value = inputValue === "" ? undefined : parseFloat(inputValue);

    if (typeof value === "number" && !isNaN(value)) {
      setFontValues((prevFontValues) =>
        prevFontValues.map((font, i) =>
          i === index ? { ...font, price: value } : font
        )
      );
    }
  };

  const handleSaveChanges = async () => {
    const validFontValues = fontValues.filter(
      (font) => typeof font.price === "number" && !isNaN(font.price)
    );

    const updatedValues = validFontValues.filter(
      (font, index) => font.price !== originalFontValues[index].price
    );

    if (updatedValues.length > 0 || Number(baseValue) !== originalBaseValue) {
      setSavingChanges(true);
      FontValuesController.updateFontValues({
        userId: user.id,
        minimumCost: Number(baseValue) ?? 0,
        fontPrices: updatedValues,
      })
        .then(() => {
          setOriginalFontValues(fontValues);
          setIsSaveEnabled(false);
        })
        .finally(() => {
          setSavingChanges(false);
        });
    } else {
      alert("Nenhuma alteração detectada.");
    }
  };

  const handleChangeAccountStatus = (status: boolean) => {
    setLoadingSwitch(true);
    AuthController.updateTrialAccount(user.id, status)
      .then(() => {
        setTrialStatus(status);
        handleSetUserAccountStatus(user, status);
      })
      .finally(() => {
        setLoadingSwitch(false);
      });
  };

  const handleSetUserAccountStatus = (
    currentUser: IUserViewModel,
    status: boolean
  ) => {
    const updatedCompanyUsers = (companyUsers ?? []).map((u) =>
      u.id === currentUser.id ? { ...u, isTrialAccount: status } : u
    );
    setCompanyUsers(updatedCompanyUsers);
    if (authUser?.id === currentUser.id) {
      setUser({ ...authUser, isTrialAccount: status });
    }
  };

  //
  useEffect(() => {
    getFontValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const hasBaseValueChanged = Number(baseValue) !== originalBaseValue;
    const hasFontValuesChanged = fontValues.some(
      (font, index) => font.price !== originalFontValues[index]?.price
    );

    setIsSaveEnabled(hasBaseValueChanged || hasFontValuesChanged);
  }, [baseValue, fontValues, originalBaseValue, originalFontValues]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        width: "80vw",
        minHeight: "80vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#293778",
          },
        }}
      >
        <ModalHeader>
          <Typography variant="h6" component="h2" color={"primary"}>
            Valores
          </Typography>

          <IconButton size="small" onClick={onClose} color="error">
            <Cancel />
          </IconButton>
        </ModalHeader>
        <Typography>Custo mínimo por dossier</Typography>
        <Container>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {viewer ? (
              <span className="value">R$ {baseValue}</span>
            ) : (
              <>
                <Typography variant="subtitle1" component="span">
                  Valor
                </Typography>
                <Input
                  min={0}
                  type="number"
                  step="0.01"
                  prefix="R$"
                  value={baseValue}
                  onChange={handleEditBaseValue}
                  placeholder="Insira um valor"
                  style={{ width: "100px", marginLeft: "10px" }}
                />
              </>
            )}
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography variant="subtitle1" component="span">
              Conta teste
            </Typography>
            <Switch
              loading={loadingSwitch}
              onChange={handleChangeAccountStatus}
              disabled={viewer}
              className="custom-switch"
              style={{
                marginLeft: "10px",
              }}
              checked={trialStatus}
            />
          </div>
        </Container>
        <Typography>Custo por fonte</Typography>
        <ModalContent ref={modalRef}>
          {loadingFontValues ? (
            <CircularProgress size="20px" color="primary" />
          ) : (
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Nome da fonte</TableCell>
                  <TableCell align="center">Preço (R$)</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {fontValues.map((font, index) => (
                  <TableRow hover key={index} sx={{ height: 50 }}>
                    <TableCell component="th" scope="row">
                      {index + 1}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {font.name}
                    </TableCell>
                    <TableCell align="center">
                      {viewer ? (
                        <span className="value">
                          {font.price.toFixed(2).replace(".", ",")}
                        </span>
                      ) : (
                        <Input
                          min={0}
                          type="number"
                          step="0.01"
                          prefix="R$"
                          defaultValue={font.price}
                          //value={font.price}
                          onChange={(e) => {
                            handleEditPrice(index, e);
                          }}
                          style={{ width: "100px" }}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </ModalContent>
        <ModalFooter>
          {!viewer && (
            <Button
              disabled={!isSaveEnabled || savingChanges}
              variant="contained"
              color="primary"
              onClick={handleSaveChanges}
              style={{ marginTop: "20px", minWidth: "150px" }}
            >
              {savingChanges ? (
                <CircularProgress size="20px" />
              ) : (
                <>Salvar Alterações</>
              )}
            </Button>
          )}
        </ModalFooter>
      </ConfigProvider>
    </Modal>
  );
}

export default FontValuesModal;
