import styled from "styled-components";
import { Paper } from "@mui/material";

export const PageContent = styled.div`
  width: 100vw;
  height: 100%;
`;

export const Container = styled.div`
  padding: 80px 5%;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  flex-grow: 1;
`;

export const PageHeader = styled(Paper)`
  padding: 30px;
  margin: 30px;
  width: 100%;

  .input-user {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-between;
  }

  .input {
    flex: 1;
    max-width: 350px;
    min-width: 150px;
  }

  .checkboxes {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    max-width: 400px;

    .checkbox {
      flex: 1;
      max-width: 120px;
    }
  }

  button {
    align-self: flex-end;
    min-width: 100px;
  }

  @media (max-width: 768px) {
    .input-user {
      flex-direction: column;
    }

    .checkboxes {
      justify-content: space-around;
    }

    button {
      align-self: center;
      width: 100%;
    }
  }
`;

export const ContentTable = styled.div`
  background-color: white;
  max-height: 57.3vh;
  overflow-x: auto;
  text-overflow: ellipsis;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px,
    rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  width: 100%;
  overflow-x: auto;
  padding-left: 2.5rem;
  padding-right: 2.5rem;

  @media (min-width: 320px) and (max-width: 599px) {
    max-height: 30vh;
    white-space: nowrap;
  }

  @media (min-height: 100px) and (max-height: 835px) {
    max-height: 45vh;
  }

  @media (min-width: 600px) and (max-width: 899px) {
    max-height: 48vh;
    white-space: nowrap;
  }
`;
