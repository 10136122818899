import {
  Edit,
  DoDisturb,
  Check,
  Search,
  KeyboardArrowDown,
  Sort,
  KeyboardDoubleArrowDown,
  MonetizationOn,
} from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { AppBar, Modals } from "components";
import { Controller, useForm } from "react-hook-form";
import { ContentTable } from "./styles";
import { useMemo, useState } from "react";
import { IUserViewModel } from "viewModels";
import { useAlert, useAuth, useCompanyUser } from "contexts";
import { EmailController } from "controllers/EmailController";
import { validateEmail } from "utils/validateEmail";
import { encryptPassword } from "utils/encryptPassword";
import { Columns } from "props";
import { generateRandomPassword } from "utils/generateRandomPassword";
import { AuthController, CompanyUserController } from "controllers";
import { getByCnpj } from "services/consultCnpj";
import { validateCnpj } from "utils/validateCnpj";
import FontValuesModal from "components/Modal/FontValuesModal";
import { TypeUser } from "types/enums";

const CompanyUser = () => {
  const alert = useAlert();
  const { user } = useAuth();
  const {
    orderBy,
    setOrder,
    hasNextPage,
    handleFetchNextPage,
    isFetchingNextPage,
    handleFetch,
    companyUsers,
    setCompanyUsers,
  } = useCompanyUser();
  const [expectedCode, setExpectedCode] = useState("");
  const [cnpj, setCnpj] = useState<string>("");
  const [companyName, setCompanyName] = useState<string>("");
  const [isLoading] = useState(false);
  const [isOpenEditUserModal, setIsOpenEditUserModal] = useState(false);
  const theme = useTheme();
  const [requesting] = useState(false);
  const [selectedCompanyUser, setSelectedCompanyUser] =
    useState<IUserViewModel>();
  const [defaultValues] = useState({
    document: "",
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    filter: "",
  });
  const [currentUser, setCurrentUser] = useState<IUserViewModel>();
  const [displayFontValuesModal, setDisplayFontValuesModal] =
    useState<boolean>(false);

  //
  const {
    control,
    watch,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm({
    shouldFocusError: false,
    reValidateMode: "onChange",
    defaultValues: useMemo(() => defaultValues, [defaultValues]),
  });
  const data = watch();

  const formatarCNPJ = (input: string): string => {
    const numerosCNPJ = input.replace(/[^\d]/g, "").substring(0, 14);
    const cnpjFormatado = `${numerosCNPJ.substring(
      0,
      2
    )}.${numerosCNPJ.substring(2, 5)}.${numerosCNPJ.substring(
      5,
      8
    )}/${numerosCNPJ.substring(8, 12)}-${numerosCNPJ.substring(12)}`;
    return cnpjFormatado;
  };

  const handleDisableCompanyUser = (id: string, status: boolean) => {
    CompanyUserController.updateDisableStatusCompany(id, status).then(
      async () => {
        setCompanyUsers((prev) => {
          const newCompanyUsers = [...prev];
          const idx = newCompanyUsers.findIndex((item) => item.id === id);
          if (idx !== -1) {
            newCompanyUsers[idx] = {
              ...newCompanyUsers[idx],
              disabled: !newCompanyUsers[idx].disabled,
            };
          }
          return newCompanyUsers;
        });

        if (status) {
          const userSelected = companyUsers?.find((u) => u.id === id);
          EmailController.send({
            to: userSelected?.email ?? "",
            subject: "Conta desativada",
            template: "template_email.html",
            replace: {
              SUBTITLE: `Olá!, ${userSelected?.name ?? ""}`,
              DESCRIPTION:
                "Sua conta foi desativada, favor entre em contato através do e-mail: ",
              ICONURL:
                "https://vigna.adv.br/legalcontrol/legalhub/e-mail/secu.png",
            },
          });
        }
      }
    );
  };

  const verifyInfos = async () => {
    clearErrors();
    let hasError = false;
    if (!user?.id) return;

    if (!validateEmail(data.email)) {
      setError("email", { message: "Email inválido" });
      hasError = true;
    } else {
      const alreadyEmail = await CompanyUserController.verifyAlreadyEmail(
        data.email
      );
      if (alreadyEmail.data) {
        setError("email", { message: "Este email já existe" });
        hasError = true;
      }
    }

    if (!data.name) {
      setError("name", { message: "Informe o nome" });
      hasError = true;
    }

    if (validateCnpj(cnpj)) {
      const resp = await getByCnpj(cnpj.replace(/\D/g, ""));
      setCompanyName(resp.companyName ?? "");
    } else {
      setError("document", { message: "Cnpj inválido" });
      hasError = true;
    }

    return hasError;
  };

  const handleCreateCompanyUser = async () => {
    const hasError = await verifyInfos();
    if (!user?.id || !data || hasError) return;

    const password = generateRandomPassword();

    AuthController.register({
      name: data.name,
      cnpj,
      email: data.email,
      companyName,
      password: encryptPassword(password),
    })
      .then((e) => {
        const newCompanyUser: IUserViewModel = {
          id: e.data.user?.id || "",
          name: e.data.user?.name || "",
          email: e.data.user?.email || "",
          document: e.data.user?.document || "",
          typeUser: e.data.user?.typeUser || 0,
          balance: e.data.user?.balance || 0,
          createdAt: new Date(e.data.user?.createdAt || new Date()),
          isTrialAccount: false,
          disabled: e.data.user?.disabled || false,
        };
        setCompanyUsers((prev) => [...prev, newCompanyUser]);
        setSelectedCompanyUser(newCompanyUser);
        EmailController.send({
          to: e.data.user?.email || data.email,
          subject: "Ativação de conta de empresa",
          template: "template_email_value.html",
          replace: {
            SUBTITLE: `Olá!, ${data.name ?? ""}`,
            VALUE: password,
            DESCRIPTION:
              "Utilize a senha abaixo para acessar sua conta empresa no Legal Control Audit:",
            ICONURL:
              "https://vigna.adv.br/legalcontrol/legalhub/e-mail/secu.png",
          },
        });
        alert.show({
          type: "alert",
          title: "Sucesso:",
          description: "Empresa criada com sucesso!",
          timeout: 3000,
        });
        handleFetch();
      })
      .catch((e) => {
        alert.show({
          type: "alert",
          title: "Falha:",
          description: e.response.data.message,
          timeout: 3000,
        });
      });
  };

  const handleSort = (key: Columns) => () => {
    setOrder((prev) => {
      const newOrder = [...prev];
      const oldOrderIdx = prev.findIndex((o) => o.key === key);
      if (oldOrderIdx === -1) {
        newOrder.push({
          key,
          direction: "asc",
        });
      } else if (newOrder[oldOrderIdx].direction === "asc") {
        newOrder[oldOrderIdx].direction = "desc";
      } else {
        newOrder.splice(oldOrderIdx, 1);
      }
      return newOrder;
    });
  };

  const getSortIcon = (key: Columns) => (
    <IconButton size="small" onClick={handleSort(key)}>
      {orderBy.find((o) => o.key === key) ? (
        <KeyboardArrowDown
          sx={{
            transition: "all 0.5s ease-in",
            transform:
              orderBy.find((o) => o.key === key)?.direction === "asc"
                ? "rotate(-180deg)"
                : "",
          }}
        />
      ) : (
        <Sort />
      )}
    </IconButton>
  );

  const handleUserFontValues = (selectedUser: IUserViewModel) => {
    setCurrentUser(selectedUser);
    setDisplayFontValuesModal(true);
  };

  return (
    <>
      <AppBar />
      <Container
        // maxWidth="lg"
        sx={{
          flexGrow: 1,
          minWidth: "90vw",
          minHeight: "100vh",
          overflowY: "auto",
          pt: 10,
          pb: 4,
        }}
      >
        <Paper sx={{ p: 5, mb: 5 }}>
          <Grid>
            <Typography variant="h4" color="primary" marginBottom={2}>
              Nova Empresa
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3.5}>
                <Controller
                  name="document"
                  control={control}
                  render={({ field: { onChange, value, ...rest } }) => (
                    <TextField
                      size="small"
                      fullWidth
                      value={cnpj}
                      error={!!errors.document?.message}
                      onChange={(e) => setCnpj(formatarCNPJ(e.target.value))}
                      helperText={errors.document?.message}
                      placeholder="Informe o Cnpj"
                      type="text"
                      name="cnpj"
                      label="Cnpj"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3.5}>
                <Controller
                  name="name"
                  control={control}
                  render={({ field: { onChange, value, ...rest } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Nome"
                      value={value}
                      error={!!errors.name?.message}
                      helperText={errors.name?.message}
                      placeholder="teste"
                      onChange={(e) => {
                        onChange(e.target.value);
                        clearErrors("name");
                      }}
                      {...rest}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3.5}>
                <Controller
                  name="email"
                  control={control}
                  render={({ field: { onChange, value, ...rest } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="E-mail"
                      value={value}
                      error={!!errors.email?.message}
                      helperText={errors.email?.message}
                      placeholder="Informe o E-mail"
                      onChange={(e) => {
                        onChange(e.target.value);
                        clearErrors("email");
                      }}
                      {...rest}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={2}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Button
                  style={{ width: "12rem" }}
                  type="submit"
                  variant="contained"
                  sx={{ cursor: requesting ? "wait" : "pointer" }}
                  onClick={() => {
                    handleCreateCompanyUser();
                  }}
                >
                  <Typography variant="button">Salvar</Typography>
                  {requesting && (
                    <CircularProgress
                      size={15}
                      sx={{ ml: 1, color: "white" }}
                    />
                  )}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <ContentTable>
          <Grid display="flex" justifyContent="end" margin="0.5rem 0">
            <Controller
              control={control}
              name="filter"
              render={({ field: { onChange, value } }) => (
                <TextField
                  size="small"
                  label="Pesquisar"
                  variant="standard"
                  type="search"
                  placeholder="O que deseja buscar?"
                  sx={{ width: 250 }}
                  onChange={onChange}
                  value={value}
                  InputProps={{
                    sx: {
                      pl: 1,
                    },
                    endAdornment: <Search />,
                  }}
                />
              )}
            />
          </Grid>
          <Table stickyHeader aria-label="listModels">
            <TableHead>
              <TableRow
                sx={{
                  th: {
                    backgroundColor: "white",
                    "& p": {
                      fontWeight: "bold",
                      color: theme.palette.primary.main,
                    },
                    "& div": {
                      display: "flex",
                      alignItems: "center",

                      "& button": {
                        ml: 1,
                      },
                    },
                  },
                }}
              >
                <TableCell>
                  <Grid>
                    <Typography>Cnpj</Typography>
                    {getSortIcon("document")}
                  </Grid>
                </TableCell>
                <TableCell>
                  <Grid>
                    <Typography>Nome</Typography>
                    {getSortIcon("name")}
                  </Grid>
                </TableCell>
                <TableCell>
                  <Grid>
                    <Typography>Email</Typography>
                  </Grid>
                </TableCell>
                <TableCell>
                  <Grid>
                    <Typography>Data de criação</Typography>
                    {getSortIcon("date")}
                  </Grid>
                </TableCell>
                <TableCell>
                  <Grid>
                    <Typography>Editar</Typography>
                  </Grid>
                </TableCell>
                <TableCell>
                  <Typography>Habilitado</Typography>
                </TableCell>
                <TableCell>
                  <Typography>Valores</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    <Grid
                      item
                      alignItems="center"
                      sx={{ minHeight: "65px !important" }}
                    >
                      <CircularProgress size={15} />
                      <Typography
                        paddingLeft={1}
                        variant="caption"
                        color="text"
                        sx={{ fontStyle: "italic" }}
                      >
                        Carregando lista de empresas...
                      </Typography>
                    </Grid>
                  </TableCell>
                </TableRow>
              ) : !companyUsers?.length ? (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    <Typography
                      paddingLeft={1}
                      variant="caption"
                      color="text"
                      sx={{ fontStyle: "italic" }}
                    >
                      Nenhuma empresa encontrada...
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                companyUsers
                  .filter((m) =>
                    data.filter
                      ? m.name
                          .toLowerCase()
                          .includes(data.filter.toLowerCase()) ||
                        m.email
                          .toLowerCase()
                          .includes(data.filter.toLowerCase()) ||
                        new Date(m.createdAt)
                          .toLocaleDateString("pt-BR", {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                          })
                          .includes(data.filter)
                      : companyUsers
                  )
                  .map((item) => (
                    <TableRow key={item.id} data-testid="rowCompanies">
                      <TableCell>
                        <Typography>{item.document}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{item.name}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{item.email}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {new Date(item.createdAt).toLocaleDateString(
                            "pt-BR",
                            {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            }
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() => {
                            setIsOpenEditUserModal(true);
                            setSelectedCompanyUser(item);
                          }}
                        >
                          <Edit color="primary" />
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() => {
                            handleDisableCompanyUser(item.id, !item.disabled);
                          }}
                        >
                          {item.disabled ? (
                            <DoDisturb color="error" />
                          ) : (
                            <Check color="success" />
                          )}
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        <IconButton>
                          <MonetizationOn
                            color="primary"
                            onClick={() => handleUserFontValues(item)}
                          />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
              )}
              {hasNextPage && (
                <TableRow>
                  <TableCell colSpan={8} align="center">
                    {isFetchingNextPage ? (
                      <Grid
                        item
                        alignItems="center"
                        sx={{ minHeight: "65px !important" }}
                      >
                        <CircularProgress size={15} />
                        <Typography
                          paddingLeft={1}
                          variant="caption"
                          color="text"
                          sx={{ fontStyle: "italic" }}
                        >
                          Carregando empresas...
                        </Typography>
                      </Grid>
                    ) : (
                      <Button
                        onClick={() => handleFetchNextPage()}
                        sx={{ px: 2 }}
                      >
                        <KeyboardDoubleArrowDown />
                        <Typography paddingLeft={2} variant="button">
                          Carregar mais...
                        </Typography>
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </ContentTable>
        <Modals.EditCompanyUserModal
          open={isOpenEditUserModal}
          onCodeChange={(code) => setExpectedCode(code)}
          dataCode={{
            codeExpected: expectedCode,
            contactLabel: "E-mail",
            contactValue: data.email,
          }}
          companyUser={selectedCompanyUser || ({} as IUserViewModel)}
          onClose={() => setIsOpenEditUserModal(false)}
        />

        {displayFontValuesModal && currentUser && (
          <FontValuesModal
            open={displayFontValuesModal}
            user={currentUser}
            viewer={user.typeUser !== TypeUser.ADM}
            onClose={() => setDisplayFontValuesModal(false)}
          />
        )}
      </Container>
    </>
  );
};

export default CompanyUser;
