import styled from "styled-components";

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

export const ModalContent = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 20px;
  overflow-y: auto;
  max-height: 60vh;
`;

export const LogDisplay = styled.div`
  width: 100%;
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 4px;
  white-space: pre-line;
  overflow-wrap: break-word;
  word-break: break-word;
  overflow-y: auto;
  font-family: "Courier New", Courier, monospace;
  font-size: 14px;
  color: #333;
  flex-grow: 1;
`;
