import styled from "styled-components";

export const Container = styled.div`
  padding: 20px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

export const ModalContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 20px;
  overflow: auto;

  .available {
    color: #51b551;
    font-size: 13px;
  }
  .unavailable {
    color: #b54343;
    font-size: 13px;
  }
  .processing {
    color: #adadad;
    font-size: 13px;
  }
`;
