import styled from "styled-components";

export const ContentTable = styled.div`
  background-color: white;
  max-height: 56vh;
  overflow-x: auto;
  text-overflow: ellipsis;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px,
    rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  width: 100%;
  overflow-x: auto;
  padding-left: 2.5rem;
  padding-right: 2.5rem;

  .dossierDescription {
    width: 9.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1.2rem;
  }

  .buttonError {
    border: none;
    background-color: transparent;
    text-decoration: none;
  }

  @media (min-width: 300px) and (max-width: 599px) {
    max-height: 38vh;
    white-space: nowrap;
  }

  @media (min-width: 600px) and (max-width: 899px) {
    max-height: 47.5vh;
    white-space: nowrap;
  }
  .dossie-value {
    font-size: small;
    color: ${({ theme }) => theme.colors.text};
    white-space: nowrap;
  }

  .dossie-value-trial {
    text-decoration: line-through;
    font-size: small;
    color: ${({ theme }) => theme.colors.text};
    white-space: nowrap;
  }
`;
