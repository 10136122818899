import { FontMonitoringContextProps } from "contexts-types";
import { Columns, Props } from "props";
import React, { createContext, useContext, useEffect } from "react";
import { useState } from "react";
import {
  IFontStatusHistoryViewModel,
  IFontTestViewModel,
  IHistoryTestDataViewModel,
} from "viewModels";
import { useAuth } from "./auth";
import { useInfiniteQuery } from "react-query";

import { FontMonitoringController } from "controllers/FontMonitoringController";
import useWebSocket from "hooks/useWebSocket";

const PAGE_SIZE = 10;
const FontMonitoringContext = createContext<FontMonitoringContextProps>(
  {} as FontMonitoringContextProps
);

const FontMonitoringProvider: React.FC<Props> = ({ children }) => {
  const { user, logged } = useAuth();
  const [reports, setReports] = useState<IFontTestViewModel[]>([]);
  const [fontStatusHistory, setFontStatusHistory] = useState<
    IFontStatusHistoryViewModel[]
  >([]);
  const [testHistory, setTestHistory] = useState<IHistoryTestDataViewModel[]>(
    []
  );
  const [isLoadingTestHistory, setIsLoadingTestHistory] =
    useState<boolean>(false);
  const [isLoadingFontsHistory, setIsLoadingFontsHistory] =
    useState<boolean>(false);

  const {
    data: results,
    isLoading,
    hasNextPage,
    fetchNextPage,
    refetch,
    isFetchingNextPage,
  } = useInfiniteQuery(
    ["fonts-report"],
    ({ pageParam = 1 }) =>
      FontMonitoringController.getReport<Columns>({
        page: pageParam,
        take: PAGE_SIZE,
      }).then((res) => res.data),
    {
      enabled: !!user?.id && logged,
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = allPages.length + 1;
        return lastPage.length < PAGE_SIZE ? undefined : nextPage;
      },
    }
  );

  const handleFetchNextPage = () => {
    fetchNextPage();
  };

  const handleFetch = () => {
    refetch();
  };

  useWebSocket<IFontTestViewModel>({
    url: `font-report/${user?.id}`,
    onMessage: (report) => {
      setReports((prev) => {
        const existingReportIndex = prev.findIndex((t) => t.id === report.id);
        if (existingReportIndex !== -1) {
          const updatedReports = [...prev];
          updatedReports[existingReportIndex] = report;
          return updatedReports;
        } else {
          return [report, ...prev];
        }
      });
    },
  });

  useEffect(() => {
    setReports(results?.pages?.flat() ?? []);
  }, [results, setReports]);

  useEffect(() => {
    if (!!user?.id && logged) {
      setIsLoadingFontsHistory(true);
      FontMonitoringController.getFontsHistory()
        .then((response) => {
          setFontStatusHistory(response.data);
        })
        .finally(() => setIsLoadingFontsHistory(false));

      setIsLoadingTestHistory(true);
      FontMonitoringController.getReportHistory()
        .then((response) => {
          setTestHistory(response.data);
        })
        .finally(() => setIsLoadingTestHistory(false));
    }
  }, [user, logged]);

  return (
    <FontMonitoringContext.Provider
      value={{
        reports,
        isLoading,
        hasNextPage,
        isFetchingNextPage,
        handleFetchNextPage,
        handleFetch,
        fontStatusHistory,
        testHistory,
        isLoadingTestHistory,
        isLoadingFontsHistory,
      }}
    >
      {children}
    </FontMonitoringContext.Provider>
  );
};

export const useFontMonitoring: () => FontMonitoringContextProps = () =>
  useContext(FontMonitoringContext);

export default FontMonitoringProvider;
