import styled from "styled-components";

export const CardContainer = styled.div`
  .cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .balanceValue {
    display: flex;
    align-items: flex-start;
    margin-left: 10px;
  }
  .buttons {
    display: flex;
    margin: 0 10px;
    justify-content: end;
  }
`;
export const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .start-report {
    width: 200px;
  }
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;

  .start-report {
    max-width: 200px;
  }
`;

export const HistoryGraph = styled.div`
  height: 300px;
  display: flex;
`;

export const BarGraph = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 2500px;
  overflow: hidden;
`;
