import React from "react";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
} from "@mui/lab";

import { ITransactionViewModel } from "viewModels";
import { PaymentStatus, TransactionType } from "types/enums";
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from "@mui/lab/TimelineOppositeContent";

import {
  Ban,
  CircleCheckBig,
  CircleDot,
  CircleX,
  ClockAlert,
  ClockArrowUp,
  RotateCcw,
  TrendingDown,
  TrendingUp,
} from "lucide-react";
import { TimeLineContent, TimeLineRow, Title } from "./styles";

interface TransactionTimelineProps {
  transactions: ITransactionViewModel[];
  onClick: (transaction: ITransactionViewModel) => void;
}

const getStatusIcon = (status: PaymentStatus) => {
  switch (status) {
    case PaymentStatus.COMPLETED:
      return (
        <>
          <CircleCheckBig className="icon" color="#3fbd45" />
          <TimelineConnector sx={{ bgcolor: "#3fbd45" }} />
        </>
      );
    case PaymentStatus.PENDING:
      return (
        <>
          <ClockAlert className="icon" color="#faa628" />
          <TimelineConnector sx={{ bgcolor: "#faa628" }} />
        </>
      );
    case PaymentStatus.FAILED:
      return (
        <>
          <Ban className="icon" color="#fa0c04" />
          <TimelineConnector sx={{ bgcolor: "#fa0c04" }} />
        </>
      );
    case PaymentStatus.CANCELLED:
      return (
        <>
          <CircleX className="icon" color="#fa0c04" />
          <TimelineConnector sx={{ bgcolor: "#fa0c04" }} />
        </>
      );
    case PaymentStatus.REFUNDED:
      return (
        <>
          <RotateCcw className="icon" color="#3fbd45" />
          <TimelineConnector sx={{ bgcolor: "#3fbd45" }} />
        </>
      );
    case PaymentStatus.PROCESSING:
      return (
        <>
          <ClockArrowUp className="icon" color="#f5f232" />
          <TimelineConnector sx={{ bgcolor: "#f5f232" }} />
        </>
      );
    case PaymentStatus.EXPIRED:
      return (
        <>
          <ClockAlert className="icon" color="#fd7672" />
          <TimelineConnector sx={{ bgcolor: "#fd7672" }} />
        </>
      );
    default:
      return (
        <>
          <CircleDot className="icon" color="#797979" />
          <TimelineConnector sx={{ bgcolor: "#797979" }} />
        </>
      );
  }
};

const TransactionTimeline: React.FC<TransactionTimelineProps> = ({
  transactions,
  onClick,
}) => {
  return (
    <TimeLineContent>
      <Timeline
        sx={{
          [`& .${timelineOppositeContentClasses.root}`]: {
            flex: 0,
            padding: "2px",
            marginRight: "3%",
          },
          padding: 0,
        }}
      >
        {transactions.map((transaction) => (
          <TimeLineRow
            key={transaction.id}
            onClick={() => onClick(transaction)}
          >
            <TimelineItem key={transaction.id}>
              <TimelineOppositeContent color="textSecondary">
                <p className="hour">
                  {new Date(transaction.createdAt).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}{" "}
                </p>
                <p className="date">
                  {new Date(transaction.createdAt).toLocaleDateString()}{" "}
                </p>
              </TimelineOppositeContent>
              <TimelineSeparator>
                {getStatusIcon(transaction.status)}
              </TimelineSeparator>

              <TimelineContent sx={{ paddingTop: 0 }}>
                <div className="timeline-content">
                  {transaction.transactionType === TransactionType.CREDIT ? (
                    <Title>
                      <p>CRÉDITO</p>

                      <TrendingUp className="icon-title" color="#3fbd45" />
                    </Title>
                  ) : (
                    <Title>
                      <p>DÉBITO</p>

                      <TrendingDown className="icon-title" color="#ff3535" />
                    </Title>
                  )}

                  <p className="amount">
                    R$ {(transaction.amount / 100).toFixed(2)}
                  </p>
                  <p className="id">{transaction.description}</p>
                  {transaction.metadata?.createdByName !== undefined && (
                    <p className="id">
                      Criado por: {transaction.metadata.createdByName}
                    </p>
                  )}
                </div>
              </TimelineContent>
            </TimelineItem>
          </TimeLineRow>
        ))}
      </Timeline>
    </TimeLineContent>
  );
};

export default TransactionTimeline;
